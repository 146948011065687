import React, { useEffect, useState, useRef, useMemo } from "react"

import PopMobile from "./component/mobile"
import { useMediaPredicate } from "react-media-hook"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { Helmet } from "react-helmet"

import vid1 from "../../../assets/lucky-march/desktop/0.5.webm"
import vid2 from "../../../assets/lucky-march/desktop/0.75.webm"
import vid3 from "../../../assets/lucky-march/desktop/1.webm"
import vid4 from "../../../assets/lucky-march/desktop/1.25.webm"
import vid5 from "../../../assets/lucky-march/desktop/1.5.webm"
import vid6 from "../../../assets/lucky-march/desktop/1.75.webm"
import vid7 from "../../../assets/lucky-march/desktop/2.webm"
import bgWord from "../../../assets/lucky-march/desktop/bg-word.png"
import videoOpening from "../../../assets/lucky-march/mobile/opening.webm"
import SEO from "../../../components/seo"
import Layout from "../../../components/layout"

const axios = require("axios")

const SUBMIT_POP = gql`
  mutation SubmitFormDoubleLuckyDraw(
    $fullName: String
    $phoneNumber: String
    $email: String
    $product: String
    $blok: String
    $unit: String
    $salesName: String
    $officeName: String
    $discount: String
    $payment: String
    $promo: String
  ) {
    submit2DoubleLuckyDraw(
      input: {
        clientMutationId: ""
        fullName: $fullName
        phoneNumber: $phoneNumber
        email: $email
        product: $product
        blok: $blok
        unit: $unit
        salesName: $salesName
        officeName: $officeName
        discount: $discount
        payment: $payment
        promo: $promo
      }
    ) {
      status
      message
    }
  }
`

const DoubleLuckyDrawPopPage = ({ location }) => {
  const resizeScreen = useMediaPredicate("(max-width: 800px)")

  const [submitPop] = useMutation(SUBMIT_POP)
  const [successModal, setSuccessModal] = useState(false)
  const [smsSendComplete, setSmsSendComplete] = useState(true)
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(30, 51, 62, 0.9)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }
  const ogUrl = `${process.env.BASE_URL}${location.path ? location.path : ""}`
  const [phone, setPhone] = useState(null)

  function getRandomValue() {
    const list = [
      { value: 0, weight: 25 },
      { value: 1, weight: 25 },
      { value: 2, weight: 20 },
      { value: 3, weight: 15 },
      { value: 4, weight: 5 },
      { value: 5, weight: 5 },
      { value: 6, weight: 5 },
    ]

    // Calculate the cumulative weights
    let cumulativeWeight = 0
    const cumulativeList = list.map(item => {
      cumulativeWeight += item.weight
      return { ...item, cumulativeWeight }
    })

    // Generate a random number between 1 and 100
    const randomNum = Math.random() * 100

    // Find the corresponding value
    const selected = cumulativeList.find(
      item => randomNum <= item.cumulativeWeight
    )
    return selected?.value || 0
  }

  function submitData({ data }) {
    const name = sessionStorage.getItem("customerName")
    const email = sessionStorage.getItem("customerEmail")
    const disc = data
    const product = sessionStorage.getItem("product")
    const blok = sessionStorage.getItem("blok")
    const unit = sessionStorage.getItem("unit")
    const salesName = sessionStorage.getItem("salesName")
    const officeName = sessionStorage.getItem("officeName")
    const formatNumber = phone.split("+").join("")
    const promo = "Additional Discount"
    submitPop({
      variables: {
        fullName: name,
        phoneNumber: formatNumber,
        email: email,
        product: product,
        blok: blok,
        unit: unit,
        salesName: salesName,
        officeName: officeName,
        discount: disc,
        payment: "",
        promo: promo,
      },
    })
      .then(res => {
        sendSms({
          name,
          phone,
          disc,
          product,
          salesName,
          promo,
        })
        sessionStorage.removeItem("customerName")
        sessionStorage.removeItem("customerEmail")
        sessionStorage.removeItem("customerPhone")
        sessionStorage.removeItem("product")
        sessionStorage.removeItem("blok")
        sessionStorage.removeItem("unit")
        sessionStorage.removeItem("salesName")
        sessionStorage.removeItem("officeName")
        sessionStorage.removeItem("isAgree")
        sessionStorage.removeItem("promo")
        setPhone(null)
      })
      .catch(e => console.log("Error submitData", e))
  }

  function sendSms(param) {
    // const url = "https://sinarmasland.com/api/sms/pop"
    const url = process.env.BASE_URL + "/api/sms/pop"

    // Selamat Bpk/Ibu [Field Nama] , Anda mendapatkan Additional Discount sebesar [Jumlah Persentase] dari Program Lucky Draw Sinar Mas Land. Simpan SMS ini sebagai bukti klaim.
    const message = `Selamat Bpk/Ibu ${
      param?.name?.split?.(" ")?.[0]
    }, Anda mendapatkan ${param.promo} sebesar ${
      param.disc
    } dari Program Lucky Draw Sinar Mas Land. Simpan SMS ini sebagai bukti klaim.`

    var data = JSON.stringify({
      phoneNumber: param.phone,
      message: encodeURI(message),
    })
    const config = {
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    }

    axios(config)
      .then(function(response) {
        console.log(JSON.stringify(response.data))
        setSmsSendComplete(true)
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  // Animation loop

  useEffect(() => {
    // detect if user has input phone number in form
    setPhone(sessionStorage.getItem("customerPhone"))

    let navbarDesktop = document
      .getElementById("desktopNav")
      .querySelectorAll(".nav-link")

    if (resizeScreen) {
      // setBackgroundCanvas(bgCanvasMobile)
      document.getElementsByClassName(
        "logo-secondary"
      )[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    } else {
      document.getElementsByClassName(
        "logo-secondary"
      )[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`

      for (let i = 0; i < navbarDesktop.length; i++) {
        navbarDesktop[i].style.color = "#000"
      }
    }

    document.querySelector("#lang-wrapper").classList.remove("d-flex")
    document.querySelector("#lang-wrapper").classList.add("d-none")
  }, [])

  const handleSuccessModal = () => {
    setSuccessModal(false)
    window.location.href = "/thr-luckydraw-march/thankyou"
  }

  const promos = [
    {
      vidSrc: vid1,
      value: "0.5%",
    },
    {
      vidSrc: vid2,
      value: "0.75%",
    },
    {
      vidSrc: vid3,
      value: "1%",
    },
    {
      vidSrc: vid4,
      value: "1.25%",
    },
    {
      vidSrc: vid5,
      value: "1.5%",
    },
    {
      vidSrc: vid6,
      value: "1.75%",
    },
    {
      vidSrc: vid7,
      value: "2%",
    },
  ]

  const otherProps = {
    isMobile: resizeScreen ? true : false,
    location,
    successModal,
    customStyles,
    handleSuccessModal,
    smsSendComplete,
    setSuccessModal,
    getRandomValue,
    submitData,
    phone,
    promos,
    ogUrl,
    bgWord,
    videoOpening,
  }

  return (
    <Layout location={location} currentLocation={location.uri}>
      <SEO
        title={`Double Lucky Draw, Dapatkan Tambahan Promonya`}
        fbTitle={`Double Lucky Draw, Dapatkan Tambahan Promonya`}
        twitterTitle={`Double Lucky Draw, Dapatkan Tambahan Promonya`}
        description={`Special Promo Sinar Mas Land! Mainkan Double Lucky Draw 2025 dan menangkan tambahan promo special dari Sinar Mas Land`}
        fbDescription={`Special Promo Sinar Mas Land! Mainkan Double Lucky Draw 2025 dan menangkan tambahan promo special dari Sinar Mas Land`}
        twitterDescription={`Special Promo Sinar Mas Land! Mainkan Double Lucky Draw 2025 dan menangkan tambahan promo special dari Sinar Mas Land`}
        url={ogUrl}
        img={`https://www.sinarmasland.com/app/uploads/2024/12/MoveInQuickly-Logo-whiteoutline@2x-1.png`}
        fbImg={`https://www.sinarmasland.com/app/uploads/2024/12/MoveInQuickly-Logo-whiteoutline@2x-1.png`}
        twitterImg={`https://www.sinarmasland.com/app/uploads/2024/12/MoveInQuickly-Logo-whiteoutline@2x-1.png`}
      />
      <Helmet>
        <meta name="Permissions-Policy" content="autoplay=(self)"></meta>
        <body className="bd-page" />
      </Helmet>
      <PopMobile {...otherProps} />
    </Layout>
  )
}

export default DoubleLuckyDrawPopPage
