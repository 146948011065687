import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Modal from "react-modal"

import SEO from "../../../../components/seo"
import Layout from "../../../../components/layout"
import { Section, Container } from "../../../../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const DoubleLuckyDrawPopPage = props => {
  const {
    successModal,
    customStyles,
    handleSuccessModal,
    smsSendComplete,
    setSuccessModal,
    getRandomValue,
    submitData,

    phone,
    promos,
    bgWord,
    videoOpening,
  } = props

  const [videoSrc, setVideoSrc] = useState("")

  const handleClick = () => {
    if (!phone) return false
    const randomIndex = getRandomValue()
    const randomVideo = promos[randomIndex]
    setVideoSrc(randomVideo.vidSrc)
    setSuccessModal(true)
    submitData({ data: randomVideo.value })
  }

  return (
    <React.Fragment>
      <Section
        id={"sectionSurprise-mobile"}
        className="sectionSurprise m-0 p-0 text-center"
      >
        <Container
          id={"containerSurprise-mobile"}
          className="containerSurprise"
        >
          <img
            src={bgWord}
            alt="Double lucky draw"
            style={{
              width: "100%",
              maxWidth: 400,
            }}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                position: "relative",
                marginTop: 30,
                width: 400,
              }}
            >
              <video
                src={videoOpening}
                autoPlay
                muted
                playsInline
                className="rounded"
                style={{
                  width: 400,
                  objectFit: "contain",
                  animation: "scaleUp 0.8s ease-out",
                  marginBottom: -10,
                }}
              />
              <div
                style={{
                  background: "transparent",
                  width: "100%",
                  height: 182,
                  position: "absolute",
                  top: 14,
                  left: 0,
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <div
                  onClick={() => {
                    handleClick()
                    // alert("gass")
                  }}
                  style={{
                    background: "transparent",
                    width: 190,
                    marginLeft: 20,
                    height: 149,
                  }}
                ></div>
                <div
                  onClick={() => {
                    handleClick()
                    // alert("gass")
                  }}
                  style={{
                    background: "transparent",
                    width: 180,
                    height: 177,
                    marginTop: 7,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <Modal
        isOpen={successModal}
        style={customStyles}
        contentLabel="result modal"
        overlayClassName="modal-overlay-general"
      >
        <div className="modal-luckydip-success relative modal-body text-center d-flex justify-content-center">
          {/* <img src={imageSuccess} alt="Selamat" /> */}
          <div
            className="rounded"
            style={{
              position: "relative",
              width: "73%",
              height: "73%",
              backgroundColor: "white",
              padding: 10,
            }}
          >
            <video
              src={videoSrc}
              autoPlay
              muted
              playsInline
              className="rounded"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                animation: "scaleUp 0.8s ease-out",
              }}
            />
            {smsSendComplete ? (
              <button
                className="btn btn-primary btn-close-luckydip"
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
                onClick={handleSuccessModal}
              >
                &times;
              </button>
            ) : null}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}
export default DoubleLuckyDrawPopPage
